.Form {
  label {
    font-size: 0.9em;
    font-weight: 700;
    display: block;
    height: 2em;
  }

  input {
    width: 100%;
    padding: 0.3em;
    border-radius: 5px;
    border: 1px solid black;
    height: 3.6em;
    margin-bottom: 14px;
    box-sizing: border-box;
  }
}
