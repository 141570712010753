@import '../../../styles/variables.scss';

.Table {
    width: 100%;

    td {
        border: 1px solid $bg-dark;
        padding: 8px;
    }

    thead {
        td {
            background-color: lighten($bg-dark, 20%);
            color: white;
            font-weight: 400;
        }
    }

    tbody {
        tr:hover {
            background-color: lighten($bg-dark, 70%);
        }
    }
}