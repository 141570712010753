@import '../../styles/variables.scss';

.App {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.Body {
    height: 100%;
    display: flex;
    flex-direction: row;

    .Sidebar {
        height: 100%;
        display: flex;
        flex-direction: column;
        flex-grow: 0;
        padding: 0px;
        flex: 0 0 $navbar-open-width;

        &.Collapsed {
            flex: 0 0 $navbar-collapsed-width;
        }
    }

    .Content {
        flex-grow: 1;
        padding: 14px;
    }
}
