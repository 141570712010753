.Logs {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 8px;
  border: none;
  resize: none;

  &:focus,
  &:focus-visible {
    outline: none;
  }
}
