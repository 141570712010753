@import "../../../styles/variables.scss";

.ToastContainer {
  position: absolute;
  right: 14px;
  top: calc(#{$header-height} + 14px);
  z-index: 300;

  .Toast {
    color: $text-light;
    min-width: 260px;
    min-height: 60px;
    border: 1px solid $bg-dark;
    border-radius: 4px;
    cursor: pointer;
    margin-bottom: 14px;

    &.Success {
      background-color: $success;
    }

    &.Warning {
      background-color: $warning;
    }

    &.Error {
      background-color: $error;
    }

    &.Info {
      background-color: $info;
    }

    .Header {
      font-weight: 600;
      border-bottom: 1px solid $bg-dark;
      padding: 14px;
    }

    .Content {
      padding: 14px;
    }
  }
}
