@import "../../../styles/variables.scss";

.Header {
  height: $header-height;
  display: flex;
  background-color: $bg-dark;

  line-height: $header-height;

  a {
    text-decoration: none;
    color: white;
    display: block;
    text-align: center;
    padding: 0px 14px;

    &.Active,
    &:hover {
      color: darken(white, 10%);
      background-color: darken($bg-dark, 5%);
    }
  }

  .Brand {
    display: block;
    margin-left: $navbar-collapsed-width;
    font-size: 1.2em;
  }

  .Nav {
    display: flex;
    flex-grow: 1;

    .NavLeft,
    .NavRight {
      display: flex;

      .NavItem {
        position: relative;

        svg {
          margin-left: 8px;
        }

        .Dropdown {
          color: white;
          background-color: lighten($bg-dark, 5%);
          position: absolute;
          right: 0px;
          top: $header-height;
          z-index: 100;
          min-width: 100%;
          box-sizing: border-box;

          a {
            height: 40px;
            line-height: 40px;
          }
        }
      }
    }

    .NavLeft {
      flex-grow: 1;
    }
  }
}
