.Bot {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;

    .TopRow, .BottomRow {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        margin-bottom: 14px;
    }

    .TopRow {
        .BotEdit {
            flex-grow: 2;
            margin-right: 14px;
        }

        .BotCommands {
            flex-grow: 6;
        }
    }

    .BottomRow {
        flex-grow: 1;

        .BotStatus {
            flex-grow: 1;
        }
    }
}