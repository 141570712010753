@import "../../../styles/variables.scss";

$background-color-light: $primary-2;
$background-color-dark: $secondary-3;

$text-color-light: #000000;
$text-color-dark: #ffffff;

.Button {
  display: inline-block;
  border: none;
  border-radius: 3px;

  &.Small {
    padding: 0.7em 1.4em;
  }

  &.Large {
    padding: 1em 2em;
  }

  &:hover {
    cursor: pointer;
  }

  &.Light {
    background-color: $background-color-light;
    color: $text-color-light;

    &:hover {
      background-color: darken($background-color-light, 10%);
    }
  }

  &.Dark {
    background-color: $background-color-dark;
    color: $text-color-dark;

    &:hover {
      background-color: darken($background-color-dark, 10%);
    }
  }
}
