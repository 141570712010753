// Colors
$bg-dark: #343a40;
$text-light: #ffffff;

$primary-1: #90c5dc;
$primary-2: #53b2dc;
$primary-3: #045c83;
$primary-4: #175069;
$primary-5: #02425e;

$secondary-1: #f1c99c;
$secondary-2: #f1a857;
$secondary-3: #cf6d00;
$secondary-4: #a66721;
$secondary-5: #964f00;

$success: #4cb050;
$warning: #ff9700;
$error: #e51c24;
$info: #9c28b1;

// Dimensions
$navbar-collapsed-width: 60px;
$navbar-open-width: 15rem;
$header-height: 60px;
