@import "../../../styles/variables.scss";

.AdminSidebar {
  background-color: $bg-dark;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.List {
  width: 100%;
  flex-grow: 1;
}

.ListItem {
  &:hover {
    background-color: darken($bg-dark, 5%);
    cursor: pointer;
  }

  display: flex;

  .ItemLabel {
    flex-grow: 1;
    margin: auto 0px;
  }

  .ItemIconLeft {
    flex-grow: 0;
    margin-right: 14px;
    margin: auto 14px auto 14px;
  }

  .ItemIconRight {
    flex-grow: 0;
    margin: auto 14px auto 14px;
  }

  .IconCollapsed {
    margin: auto auto;
  }

  color: white;
  height: 55px;
  width: 100%;
}
