@import "../../../styles/variables.scss";

$bg-color: lighten($bg-dark, 10%);
$border: 1px solid $bg-color;

.Panel {
  display: flex;
  flex-direction: column;
  border: $border;
  border-radius: 4px;
  height: 100%;

  &.Centered {
    margin: auto;
    width: 33%;
    height: auto;
  }

  .Header,
  .Footer {
    display: flex;
    color: $text-light;
    align-items: center;
    flex-grow: 0;
    padding: 0px 14px;
    background-color: $bg-color;
    overflow: hidden;
  }

  .Header {
    border-bottom: $border;
    flex-grow: 1;
    height: 50px;
    min-height: 50px;
    max-height: 50px;

    .Title {
      flex-grow: 1;
    }

    .Actions {
      flex-grow: 0;

      button {
        margin-right: 4px;
      }

      :last-child {
        margin-right: 0px;
      }
    }
  }

  .Footer {
    border-top: $border;
    padding: 16px;
  }

  .Body {
    padding: 14px;
    height: 100%;
    position: relative;

    &.Paddingless {
      padding: 0px;
    }
  }
}
