@import "../../../styles/variables.scss";

$bg-color: rgba(255, 255, 255, 0.9);
$spinner-color: $bg-dark;

.Overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  backdrop-filter: blur(2px);
  background-color: $bg-color;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 200;

  .LoadingText {
    position: absolute;
    top: calc(50% + 3em);
  }

  .Spinner,
  .Spinner:before,
  .Spinner:after {
    background: $spinner-color;
    -webkit-animation: load1 1s infinite ease-in-out;
    animation: load1 1s infinite ease-in-out;
    width: 1em;
    height: 4em;
  }
  .Spinner {
    color: $spinner-color;
    text-indent: -9999em;
    margin: 88px auto;
    position: relative;
    font-size: 11px;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
  }
  .Spinner:before,
  .Spinner:after {
    position: absolute;
    top: 0;
    content: "";
  }
  .Spinner:before {
    left: -1.5em;
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }
  .Spinner:after {
    left: 1.5em;
  }
  @-webkit-keyframes load1 {
    0%,
    80%,
    100% {
      box-shadow: 0 0;
      height: 4em;
    }
    40% {
      box-shadow: 0 -2em;
      height: 5em;
    }
  }
  @keyframes load1 {
    0%,
    80%,
    100% {
      box-shadow: 0 0;
      height: 4em;
    }
    40% {
      box-shadow: 0 -2em;
      height: 5em;
    }
  }
}
